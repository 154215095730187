exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-company-js": () => import("./../../../src/pages/about-us/company.js" /* webpackChunkName: "component---src-pages-about-us-company-js" */),
  "component---src-pages-about-us-culture-js": () => import("./../../../src/pages/about-us/culture.js" /* webpackChunkName: "component---src-pages-about-us-culture-js" */),
  "component---src-pages-about-us-how-we-work-js": () => import("./../../../src/pages/about-us/how-we-work.js" /* webpackChunkName: "component---src-pages-about-us-how-we-work-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-testimonials-js": () => import("./../../../src/pages/about-us/testimonials.js" /* webpackChunkName: "component---src-pages-about-us-testimonials-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-jobs-contentful-job-card-slug-js": () => import("./../../../src/pages/careers/jobs/{ContentfulJobCard.slug}.js" /* webpackChunkName: "component---src-pages-careers-jobs-contentful-job-card-slug-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-ebook-ai-js": () => import("./../../../src/pages/download/ebook-ai.js" /* webpackChunkName: "component---src-pages-download-ebook-ai-js" */),
  "component---src-pages-download-erbis-deck-js": () => import("./../../../src/pages/download/erbis-deck.js" /* webpackChunkName: "component---src-pages-download-erbis-deck-js" */),
  "component---src-pages-download-it-vendor-check-js": () => import("./../../../src/pages/download/it-vendor-check.js" /* webpackChunkName: "component---src-pages-download-it-vendor-check-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-download-tech-migration-js": () => import("./../../../src/pages/download/tech-migration.js" /* webpackChunkName: "component---src-pages-download-tech-migration-js" */),
  "component---src-pages-fintech-industry-js": () => import("./../../../src/pages/fintech-industry.js" /* webpackChunkName: "component---src-pages-fintech-industry-js" */),
  "component---src-pages-guides-contentful-guides-slug-js": () => import("./../../../src/pages/guides/{ContentfulGuides.slug}.js" /* webpackChunkName: "component---src-pages-guides-contentful-guides-slug-js" */),
  "component---src-pages-healthcare-software-development-js": () => import("./../../../src/pages/healthcare-software-development.js" /* webpackChunkName: "component---src-pages-healthcare-software-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-automotive-software-development-js": () => import("./../../../src/pages/industries/automotive-software-development.js" /* webpackChunkName: "component---src-pages-industries-automotive-software-development-js" */),
  "component---src-pages-industries-construction-real-estate-software-js": () => import("./../../../src/pages/industries/construction-real-estate-software.js" /* webpackChunkName: "component---src-pages-industries-construction-real-estate-software-js" */),
  "component---src-pages-industries-fintech-solutions-js": () => import("./../../../src/pages/industries/fintech-solutions.js" /* webpackChunkName: "component---src-pages-industries-fintech-solutions-js" */),
  "component---src-pages-industries-healthcare-software-development-js": () => import("./../../../src/pages/industries/healthcare-software-development.js" /* webpackChunkName: "component---src-pages-industries-healthcare-software-development-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-retail-software-development-js": () => import("./../../../src/pages/industries/retail-software-development.js" /* webpackChunkName: "component---src-pages-industries-retail-software-development-js" */),
  "component---src-pages-industries-scm-supply-chain-management-software-solutions-js": () => import("./../../../src/pages/industries/scm-supply-chain-management-software-solutions.js" /* webpackChunkName: "component---src-pages-industries-scm-supply-chain-management-software-solutions-js" */),
  "component---src-pages-policy-cookie-js": () => import("./../../../src/pages/policy/cookie.js" /* webpackChunkName: "component---src-pages-policy-cookie-js" */),
  "component---src-pages-policy-privacy-js": () => import("./../../../src/pages/policy/privacy.js" /* webpackChunkName: "component---src-pages-policy-privacy-js" */),
  "component---src-pages-projects-anti-phishing-software-development-js": () => import("./../../../src/pages/projects/anti-phishing-software-development.js" /* webpackChunkName: "component---src-pages-projects-anti-phishing-software-development-js" */),
  "component---src-pages-projects-bence-command-js": () => import("./../../../src/pages/projects/bence-command.js" /* webpackChunkName: "component---src-pages-projects-bence-command-js" */),
  "component---src-pages-projects-cloud-structure-for-saas-js": () => import("./../../../src/pages/projects/cloud-structure-for-saas.js" /* webpackChunkName: "component---src-pages-projects-cloud-structure-for-saas-js" */),
  "component---src-pages-projects-hoste-js": () => import("./../../../src/pages/projects/hoste.js" /* webpackChunkName: "component---src-pages-projects-hoste-js" */),
  "component---src-pages-projects-mastory-jsx": () => import("./../../../src/pages/projects/mastory.jsx" /* webpackChunkName: "component---src-pages-projects-mastory-jsx" */),
  "component---src-pages-projects-project-web-js": () => import("./../../../src/pages/projects/project-web.js" /* webpackChunkName: "component---src-pages-projects-project-web-js" */),
  "component---src-pages-projects-property-management-app-js": () => import("./../../../src/pages/projects/property-management-app.js" /* webpackChunkName: "component---src-pages-projects-property-management-app-js" */),
  "component---src-pages-projects-supply-chain-management-system-js": () => import("./../../../src/pages/projects/supply-chain-management-system.js" /* webpackChunkName: "component---src-pages-projects-supply-chain-management-system-js" */),
  "component---src-pages-projects-vsb-js": () => import("./../../../src/pages/projects/vsb.js" /* webpackChunkName: "component---src-pages-projects-vsb-js" */),
  "component---src-pages-projects-w-health-js": () => import("./../../../src/pages/projects/w-health.js" /* webpackChunkName: "component---src-pages-projects-w-health-js" */),
  "component---src-pages-projects-w-print-js": () => import("./../../../src/pages/projects/w-print.js" /* webpackChunkName: "component---src-pages-projects-w-print-js" */),
  "component---src-pages-real-estate-software-development-services-js": () => import("./../../../src/pages/real-estate-software-development-services.js" /* webpackChunkName: "component---src-pages-real-estate-software-development-services-js" */),
  "component---src-pages-services-cloud-migration-js": () => import("./../../../src/pages/services/cloud-migration.js" /* webpackChunkName: "component---src-pages-services-cloud-migration-js" */),
  "component---src-pages-services-digital-transformation-js": () => import("./../../../src/pages/services/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-digital-transformation-js" */),
  "component---src-pages-services-discovery-phase-js": () => import("./../../../src/pages/services/discovery-phase.js" /* webpackChunkName: "component---src-pages-services-discovery-phase-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-it-consulting-js": () => import("./../../../src/pages/services/it-consulting.js" /* webpackChunkName: "component---src-pages-services-it-consulting-js" */),
  "component---src-pages-services-market-research-js": () => import("./../../../src/pages/services/market-research.js" /* webpackChunkName: "component---src-pages-services-market-research-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-product-development-js": () => import("./../../../src/pages/services/product-development.js" /* webpackChunkName: "component---src-pages-services-product-development-js" */),
  "component---src-pages-services-software-engineering-js": () => import("./../../../src/pages/services/software-engineering.js" /* webpackChunkName: "component---src-pages-services-software-engineering-js" */),
  "component---src-pages-services-tech-client-support-js": () => import("./../../../src/pages/services/tech-client-support.js" /* webpackChunkName: "component---src-pages-services-tech-client-support-js" */),
  "component---src-pages-services-ui-ux-design-js": () => import("./../../../src/pages/services/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-software-development-as-a-service-js": () => import("./../../../src/pages/software-development-as-a-service.js" /* webpackChunkName: "component---src-pages-software-development-as-a-service-js" */),
  "component---src-pages-tech-services-for-supply-chain-js": () => import("./../../../src/pages/tech-services-for-supply-chain.js" /* webpackChunkName: "component---src-pages-tech-services-for-supply-chain-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-why-erbis-js": () => import("./../../../src/pages/why-erbis.js" /* webpackChunkName: "component---src-pages-why-erbis-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-guide-page-js": () => import("./../../../src/templates/guide-page.js" /* webpackChunkName: "component---src-templates-guide-page-js" */)
}

