import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import slugify from "@sindresorhus/slugify";
import { Helmet } from "react-helmet";

const richTextImages = {};

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => {
      if (typeof children[0] == "string") {
        return <h2 id={slugify(children[0].toLowerCase())}>{children}</h2>;
      }
      return (
        <h2 id={slugify(children[0].props.children.toLowerCase())}>
          {children}
        </h2>
      );
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      if (typeof children[0] == "string") {
        return (
          <div className="source-links" id={slugify(children[0].toLowerCase())}>
            {children}
          </div>
        );
      }
      return (
        <div
          className="source-links"
          id={slugify(children[0].props.children.toLowerCase())}
        >
          {children}
        </div>
      );
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      if (typeof children[0] == "string") {
        return (
          <div
            className="img_call-to-action"
            id={slugify(children[0].toLowerCase())}
          >
            {children}
          </div>
        );
      }
      return (
        <div
          className="img_call-to-action"
          id={slugify(children[0].props.children.toLowerCase())}
        >
          {children}
        </div>
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      // console.log(node.data.uri);

      if (node.data.uri.indexOf("youtube.com") !== -1) {
        return (
          <div>
            <iframe
              loading="lazy"
              src={node?.data?.uri}
              width="100%"
              height="375"
              frameBorder="0"
              allowFullScreen={true}
              title="Embedded YouTube Video"
            />
          </div>
        );
      } else
        return (
          <a
            href={`${node.data.uri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.content[0].value}
          </a>
        );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const imageData = richTextImages[node.data.target.sys.id];
      const image = getImage(imageData.image);

      return (
        <>
          <GatsbyImage image={image} alt={imageData.alt} />
          <figcaption>{imageData.alt}</figcaption>
        </>
      );
    },
  },
};

const ContentfulRichText = ({ richText }) => {
  const [activeSection, setActiveSection] = useState(-1);

  const toggleAccordion = (index) => {
    setActiveSection((prevSection) => (prevSection === index ? -1 : index));
  };
  let faq;
  const mainEntity = [];

  richText.references?.map(
    (reference) =>
      (richTextImages[reference.contentful_id] = {
        image: reference.gatsbyImageData,
        alt: reference.description,
      })
  );

  const content = JSON.parse(richText.raw);

  const faqInd = content.content.findIndex((x) =>
    x.content.find((y) => y.value === "FAQ")
  );

  if (faqInd !== -1) {
    faq = content.content.slice(faqInd);
    content.content = content.content.slice(0, faqInd);

    faq.slice(1).map((x) => {
      if (x.nodeType === "heading-3") {
        mainEntity.push({
          "@type": "Question",
          name: x.content[0].value,
          acceptedAnswer: {
            "@type": "Answer",
            text: "",
          },
        });
      } else {
        if (mainEntity.length) {
          mainEntity[mainEntity.length - 1].acceptedAnswer.text +=
            x.content[0].value;
        } else {
          mainEntity.push({
            "@type": "Question",
            name: "Question",
            acceptedAnswer: {
              "@type": "Answer",
              text: x.content[0].value,
            },
          });
        }
      }
    });
  }

  const blogComponent = () => {
    if (faq) {
      return (
        <>
          <Helmet>
            <script type="application/ld+json" defer>
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity,
              })}
            </script>
          </Helmet>
          <div>{documentToReactComponents(content, options)}</div>
          <div className="faq-blog">
            {documentToReactComponents(faq[0], options)}
          </div>

          <div className="accordion">
            <div className="accordion-section">
              {faq &&
                faq.slice(1).map((x, i) => {
                  const isHeading = x.nodeType === "heading-3";
                  const sectionId = `accordion-${i}`;

                  return (
                    <React.Fragment key={i}>
                      {isHeading ? (
                        <a
                          id={i}
                          className={`accordion-section-title ${
                            activeSection === i ? "active" : ""
                          }`}
                          href={`#${sectionId}`}
                          onClick={() => toggleAccordion(i)}
                        >
                          {documentToReactComponents(x, options)}
                        </a>
                      ) : (
                        <div
                          className={`accordion-section-content ${
                            activeSection - i === -1
                              ? "show-content"
                              : "hide-content"
                          }`}
                        >
                          <div>{documentToReactComponents(x, options)}</div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </>
      );
    } else {
      return <div>{documentToReactComponents(content, options)}</div>;
    }
  };
  return <>{blogComponent()}</>;
};

export default ContentfulRichText;
